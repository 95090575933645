<template>
  <b-modal
    :id="modalId"
    :title="modalTitle"
    centered
    scrollable
    title-class="font-22"
    hide-footer
  >
    <b-form @submit="handleSubmit">
      <b-form-group
        label="Department"
        label-for="department"
        class="required"
      >
        <b-form-select
          id="department"
          v-model="form.department"
          :options="departmentsOptions"
          required
        />
      </b-form-group>
      <b-form-group
        label="Position"
        label-for="position"
        class="required"
      >
        <b-form-select
          id="position"
          v-model="form.position"
          :options="[{ value: null, text: 'Choose position'}, ...filterPosition]"
          required
        />
      </b-form-group>
      <b-form-group
        label="Name"
        label-for="name"
        class="required"
      >
        <b-form-input
          id="name"
          v-model="form.name"
          type="text"
          placeholder="Enter name"
          required
        />
      </b-form-group>
      <b-form-group
        label="Email"
        label-for="email"
        class="required"
      >
        <b-form-input
          id="email"
          v-model="form.email"
          type="email"
          placeholder="Enter email"
          required
        />
      </b-form-group>
      <b-form-group
        label="Phone"
        label-for="phone"
        class="required"
      >
        <b-form-input
          id="phone"
          v-model="form.phone"
          type="text"
          placeholder="Enter phone"
          required
        />
      </b-form-group>
      <b-form-group
        label="Date"
        label-for="date"
        class="required"
      >
        <date-picker
          v-model="form.date"
          type="date"
          value-type="YYYY-MM-DD"
          format="DD.MM.YYYY"
          :input-attr="{ required: 'required', id: 'date' }"
        />
      </b-form-group>
      <b-form-group
        label="Note"
        label-for="note"
        class="required"
      >
        <b-form-textarea
          id="note"
          v-model="form.note"
          required
        />
      </b-form-group>
      <b-form-group
        label="CV"
        label-for="cv"
      >
        <b-form-file
          id="cv"
          v-model="form.cv"
          placeholder="Choose file"
        />
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button
          type="submit"
          variant="primary"
        >
          {{ modalButton }}
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex';
import datePicker from 'vue2-datepicker';

export default {
  name: 'InterviewForm',
  components: {
    datePicker
  },
  props: [
    'modalId',
    'modalTitle',
    'initialData',
    'modalButton'
  ],
  data() {
    return {
      form: {
        department: null,
        position: null,
        name: '',
        email: '',
        phone: '',
        date: null,
        note: '',
        cv: null
      }
    }
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();
      this.$emit('onSubmit', this.form);
    }
  },
  watch: {
    initialData(value) {
      this.form.department = value ? value.department_id : null;
      this.form.position = value ? value.position_id : null;
      this.form.name = value ? value.name : '',
      this.form.email = value ? value.email : '',
      this.form.phone = value ? value.phone : '',
      this.form.date = value ? value.date : null,
      this.form.note = value ? value.note : '',
      this.form.cv = null
    }
  },
  computed: {
    ...mapGetters('settings', [
      'departmentsOptions',
      'positionsOptions'
    ]),
    filterPosition () {
      let result = this.positionsOptions
      if (this.form.department) {
        result = this.positionsOptions.filter(item => item.department === this.form.department)
      }
      return result
    }
  }
}
</script>