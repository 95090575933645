<template>
  <Layout v-load="loading">
    <InviteForm
        :modalId="inviteModalId"
        :initialData="currentRow"
        @onSubmit="handleInviteFormSubmit"
    />
    <InterviewForm
        :modalId="interviewModalId"
        :modalTitle="modalTitle"
        :initialData="currentRow"
        :modalButton="modalButton"
        @onSubmit="handleInterviewFormSubmit"
    />
    <div class="d-sm-flex justify-content-between mb-4 mt-3">
      <h3 class="mb-4 mb-sm-0">Interviews</h3>
      <div class="d-flex justify-content-between justify-content-sm-start">
        <b-button
            class="mr-3"
            variant="primary"
            @click="handleAdd"
        >
          Add
        </b-button>
        <div class="search">
          <Search
              @onSubmit="handleSearch"
          />
        </div>
      </div>
    </div>
    <div class="card">
      <div
          v-if="list.length > 0"
          class="table-responsive"
      >
        <table class="table table-hover mb-0" v-if="list.length > 0">
          <thead class="thead-light">
          <tr>
            <th class="id-column">
              <div>#</div>
            </th>
            <th>Candidate</th>
            <th>Note</th>
            <th>Interviewed by</th>
            <th class="actions-buttons">Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(item, index) in list"
              :key="item.id"
          >
            <th class="id-column">
              <div>{{ (page - 1) * pageLimit + index + 1 }}</div>
            </th>
            <td>
              <div class="mb-1">{{ item.name }}</div>
              <div class="text-secondary">{{ item.position ? item.position.title : '' }}</div>
              <div class="text-secondary">{{ item.department ? item.department.title : '' }}</div>
              <div>{{ $moment(item.date).format('DD.MM.YYYY') }}</div>
            </td>
            <td>{{ item.note }}</td>
            <td>{{ item.interviewer ? item.interviewer.name : '---' }}</td>
            <td class="actions-buttons">
              <div>
                <i
                    v-if="item.summary"
                    v-b-tooltip.hover title="Download CV"
                    class="bx bx-cloud-download text-success font-size-20 mr-2"
                    @click="onDownloadIconClick(item)"
                />
                <i
                    v-b-tooltip.hover title="Invite"
                    v-if="!item.invited"
                    class="bx bx-user-plus text-primary font-size-20 mr-2"
                    @click="onInviteIconClick(item)"
                />
                <i
                    class="bx bx-pencil text-warning font-size-20 mr-2"
                    @click="onEditIconClick(item)"
                />
                <i
                    class="bx bx-trash text-danger font-size-20"
                    @click="onDeleteIconClick(item.id)"
                />
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center p-3 font-size-16 text-muted" v-else><em>Interviews not found</em></div>

      <div class="pagination">
        <div class="pagination__per-page">
          <span>Per page:</span>
          <select id="perPage" class="form-control" v-model="pageLimit">
            <option
                v-for="limit in pageLimits"
                :key="limit"
                :value="limit"
            >
              {{ limit }}
            </option>
          </select>
        </div>
        <div class="pagination__page-number">
          <b-pagination
              v-if="totalRows > pageLimit"
              align="right"
              v-model="page"
              :per-page="pageLimit"
              :total-rows="totalRows"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import InviteForm from './invite_form.vue';
import InterviewForm from './interview_form.vue';
import Search from '@/components/search.vue';
import Layout from '@/router/layouts/main.vue';
import {FORM_MODE} from '@/utils/constants';

export default {
  name: 'Interviews',
  components: {
    Search,
    Layout,
    InviteForm,
    InterviewForm
  },
  data() {
    return {
      page: 1,
      search: '',
      pageLimit: 20,
      pageLimits: [20, 40, 60, 80, 100],
      formMode: '',
      currentRow: null,
      interviewModalId: 'interview-modal-id',
      inviteModalId: 'invite-modal-id',
      firstPaginationLoad: false
    }
  },
  async mounted() {
    this.firstPaginationLoad = true
    this.setPaginationConfig()
    await this.$store.dispatch('interviews/getInterviews', this.queryParams);
    await this.$store.dispatch('settings/getPositionsList')
    await this.$store.dispatch('settings/getDepartmentsList')
    this.firstPaginationLoad = false
  },
  watch: {
    pageLimit(val) {
      if (val && !this.firstPaginationLoad) {
        this.$store.dispatch('interviews/getInterviews', this.queryParams);
        this.$store.commit('interviews/SET_PAGINATION_SETTING', {page: this.page, limit: val})
        this.$router.push(`?page=${this.page}&limit=${val}`).catch(() => false)
      }
    },
    page(val) {
      if (val && !this.firstPaginationLoad) {
        this.$store.dispatch('interviews/getInterviews', this.queryParams);
        this.$store.commit('interviews/SET_PAGINATION_SETTING', {page: val, limit: this.pageLimit})
        this.$router.push(`?page=${val}&limit=${this.pageLimit}`).catch(() => false)
      }
    }
  },
  methods: {
    handleSearch(search) {
      this.search = search;
      this.$store.dispatch('interviews/getInterviews', this.queryParams);
    },
    handleAdd() {
      this.currentRow = null;
      this.formMode = FORM_MODE.CREATE;
      this.$bvModal.show(this.interviewModalId);
    },
    onInviteIconClick(item) {
      this.currentRow = item;
      this.$bvModal.show(this.inviteModalId);
    },
    onDownloadIconClick(item) {
      this.$store.dispatch('interviews/downloadInterviewCV', {
        id: item.id,
        path: item.summary
      });
    },
    onEditIconClick(row) {
      this.currentRow = row;
      this.formMode = FORM_MODE.EDIT;
      this.$bvModal.show(this.interviewModalId);
    },
    onDeleteIconClick(id) {
      this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this interview', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then((value) => {
            if (value) {
              this.$store.dispatch('interviews/deleteInterview', {
                id,
                data: this.$convertObjectToFormData({_method: 'DELETE'})
              });
            }
          })
    },
    async handleInterviewFormSubmit(data) {
      const fData = this.$convertObjectToFormData({
        name: data.name,
        email: data.email,
        phone: data.phone,
        date: data.date,
        note: data.note,
        position_id: data.position,
        department_id: data.department,
      });
      let interviewId = null;
      if (this.formMode === FORM_MODE.CREATE) {
        const {id} = await this.$store.dispatch('interviews/createInterview', fData);
        interviewId = id;
      } else {
        fData.append('_method', 'PUT');
        const {id} = await this.$store.dispatch('interviews/updateInterview', {
          id: this.currentRow.id,
          data: fData
        });
        interviewId = id;
      }
      if (interviewId && data.cv) {
        this.$store.dispatch('interviews/loadInterviewCV', {
          id: interviewId,
          data: this.$convertObjectToFormData({summary: data.cv})
        });
      }
      this.$bvModal.hide(this.interviewModalId);
    },
    handleInviteFormSubmit(data) {
      this.$store.dispatch('interviews/invite', this.$convertObjectToFormData({
        name: data.name,
        email: data.email,
        phone: data.phone,
        category_id: data.category,
        location_id: data.location,
        position_id: data.position,
        department_id: data.department,
        interview_id: this.currentRow.id,
        invite_email: data.invEmail,
        sso_enabled: data.sso
      }));
      this.$bvModal.hide(this.inviteModalId);
    },
    setPaginationConfig() {
      const {page, limit} = this.$route.query;
      if (page && limit) {
        this.page = +page + 1 ? +page : 1;
        this.pageLimit = this.pageLimits.includes(+limit) ? +limit : this.pageLimits[0];
      }
    },
  },
  computed: {
    list() {
      return this.$store.state.interviews.list;
    },
    loading() {
      return this.$store.state.interviews.loading;
    },
    modalTitle() {
      return this.formMode === FORM_MODE.CREATE ? 'Create Interview' : 'Edit Interview';
    },
    modalButton() {
      return this.formMode === FORM_MODE.CREATE ? 'Create' : 'Save';
    },
    queryParams() {
      return {
        page: this.page,
        per_page: this.pageLimit,
        search: this.search ? this.search : null
      }
    },
    totalRows() {
      return this.$store.state.interviews.totalItems;
    },
  }
}
</script>

<style lang="scss" scoped>
.search {
  width: 300px;
}

.actions-buttons {
  width: 130px;
}
</style>
