<template>
  <b-modal
    :id="modalId"
    title="New Employee"
    centered
    scrollable
    title-class="font-22"
    hide-footer
  >
    <b-form @submit="handleSubmit">
      <b-form-group
        label="Department"
        label-for="department"
        class="required"
      >
        <b-form-select
          id="department"
          v-model="form.department"
          :options="departmentsOptions"
          required
        />
      </b-form-group>
      <b-form-group
        label="Position"
        label-for="position"
        class="required"
      >
        <b-form-select
          id="position"
          v-model="form.position"
          :options="[{ value: null, text: 'Choose position'}, ...positionsOptions]"
          required
        />
      </b-form-group>
      <b-form-group
        label="Category"
        label-for="category"
        class="required"
      >
        <b-form-select
          id="category"
          v-model="form.category"
          :options="categoriesOptions"
          required
        />
      </b-form-group>
      <b-form-group
        label="Locations"
        label-for="locations"
        class="required"
      >
        <b-form-select
          id="locations"
          v-model="form.location"
          :options="locationsOptions"
          required
        />
      </b-form-group>
      <b-form-group
        label="Name"
        label-for="name"
        class="required"
      >
        <b-form-input
          id="name"
          v-model="form.name"
          type="text"
          placeholder="Enter name"
          required
        />
      </b-form-group>
      <b-form-group
        label="Invite Email"
        label-for="inv-email"
        class="required"
      >
        <b-form-input
          id="inv-email"
          v-model="form.invEmail"
          type="email"
          placeholder="Enter email"
          required
        />
      </b-form-group>
      <b-form-group
        label="Email"
        label-for="email"
        class="required"
      >
        <b-form-input
          id="email"
          v-model="form.email"
          type="email"
          placeholder="Enter email"
          required
        />
      </b-form-group>
      <b-form-group
        label="Phone"
        label-for="phone"
        class="required"
      >
        <b-form-input
          id="phone"
          v-model="form.phone"
          type="text"
          placeholder="Enter phone"
          required
        />
      </b-form-group>
<!--      <b-form-group>-->
<!--          <b-form-checkbox-->
<!--            id="sso"-->
<!--            v-model="form.sso"-->
<!--          >-->
<!--            SSO enabled-->
<!--          </b-form-checkbox>-->
<!--        </b-form-group>-->
      <div class="d-flex justify-content-end">
        <b-button
          type="submit"
          variant="primary"
        >
          Create Employee
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'InviteForm',
  props: [
    'modalId',
    'initialData',
  ],
  data() {
    return {
      form: {
        department: null,
        position: null,
        category: null,
        location: null,
        name: '',
        invEmail: '',
        email: '',
        phone: '',
        sso: false
      }
    }
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();
      this.$emit('onSubmit', this.form);
    }
  },
  watch: {
    initialData(value) {
      this.form.department = value ? value.department_id : null;
      this.form.position = value ? value.position_id : null;
      this.form.location = null;
      this.form.category = null;
      this.form.name = value ? value.name : '';
      this.form.invEmail = value ? value.email : '';
      this.form.phone = value ? value.phone : '';
      this.form.email = '';
      // this.form.sso = false;
    }
  },
  computed: {
    ...mapGetters('settings', [
      'departmentsOptions',
      'positionsOptions',
      'categoriesOptions',
      'locationsOptions'
    ])
  }
}
</script>
